import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

function UploadResume(props) {
  const [file, setFile] = useState(null);
  const [fileBase64, setFileBase64] = useState(null);
  const [message, setMessage] = useState("");
  const { state } = useLocation();

  useEffect(() => {
    if (state && state.message) {
      setMessage(state.message);
    }
  }, []);

  const isValidFileType = (filename) => {
    return /\.(jpg|jpeg|png|gif|tiff|pdf)$/i.test(filename);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log("File:", selectedFile);
    if (selectedFile && isValidFileType(selectedFile.name)) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = (e) => {
        setFileBase64(e.target.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      alert(
        "Invalid file type. Please select an image file (jpg, jpeg, png, gif, tiff, pdf).",
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("File:", file);
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const formData = {
      file: fileBase64,
      type: file.type,
    };

    try {
      const response = await axios.post(
        "https://genz-staging.feeltiptop.com/api/upload_file",
        formData,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        },
      );
      console.log(response.data);
      alert("File uploaded successfully!");
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file. Please try again.");
    }
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center">
      <h2 className="text-2xl font-bold">Upload Your Resume</h2>
      <form
        action=""
        method="POST"
        onSubmit={handleSubmit}
        className="w-full max-w-md rounded-md bg-white p-6 shadow-md"
      >
        <input
          type="file"
          accept="image/jpg, image/jpeg, image/png, image/gif, image/tiff, application/pdf"
          onChange={handleFileChange}
          className="mb-4 rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm"
        />
        <button
          type="submit"
          className="w-full bg-emerald-500 py-2 text-white transition-colors duration-300 hover:bg-emerald-600"
        >
          Upload
        </button>
      </form>
      <p>{message}</p>
    </div>
  );
}

export default UploadResume;
