import React, { useState } from "react";

function Poll({ setPoll }) {
  const [pollCreated, setPollCreated] = useState(false);
  const [error, setError] = useState(null);
  const [pollType, setPollType] = useState("regular");
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [question, setQuestion] = useState("");
  const [answers, setAnswers] = useState(["", ""]);  

  function addAnswer() {
    if (answers.length < 5) {
      setAnswers([...answers, ""]);
    } else {
      alert("You can only add up to 5 answers.");
    }
  }

  function subtractAnswer() {
    if (answers.length > 2) {
      setAnswers(answers.slice(0, -1));
      if (correctAnswer === answers.length - 1) {
        setCorrectAnswer(null);
      }
    } else {
      alert("You must have at least two answers.");
    }
  }

  function handlePollTypeChange(e) {
    setPollType(e.target.value);
    setCorrectAnswer(null);
  }

  function handleCorrectAnswerChange(index) {
    setCorrectAnswer(index);
  }

  function handleQuestionChange(e) {
    setQuestion(e.target.value);
  }

  function handleAnswerChange(index, value) {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  }

  function submitPoll() {
    setError(null);
    setPollCreated(false);

    if (!question) {
      setError("Please enter a question.");
      return;
    }

    const nonEmptyAnswers = answers.filter(answer => answer.trim() !== "");

    if (nonEmptyAnswers.length < 2) {
      setError("Please add at least two non-empty answers.");
      return;
    }

    const pollData = {
      question: question,
      selections: {},
      type: pollType,
      pollType: pollType,
      assetType: "poll"
    };

    nonEmptyAnswers.forEach((answer) => {
      pollData.selections[answer] = answer;
    });

    if (pollType === "quiz") {
      if (correctAnswer === null || answers[correctAnswer].trim() === "") {
        setError("Please select a correct answer for a quiz poll.");
        return;
      }
      pollData.answer = answers[correctAnswer];
    }

    setPollCreated(true);
    setPoll(pollData);
    console.log("Poll created:", pollData);  // For debugging
  }

  return (
    <div>
      <h2>Create Your Poll</h2>
      <div>
        <label htmlFor="pollQuestion">Poll Question:</label>
        <input type="text" id="pollQuestion" required value={question} onChange={handleQuestionChange} />
      </div>
      <div>
        <label htmlFor="pollType">Poll Type:</label>
        <select id="pollType" onChange={handlePollTypeChange} value={pollType}>
          <option value="regular">Regular Poll</option>
          <option value="quiz">Quiz (Single Correct Answer)</option>
          <option value="multi">Multi-Answer Choice</option>
        </select>
      </div>
      <div>
        <button type="button" onClick={addAnswer}>+</button>
        <button type="button" onClick={subtractAnswer}>-</button>
      </div>
      <div>
        {answers.map((answer, index) => (
          <div key={index}>
            <input
              type="text"
              value={answer}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
              placeholder={`Answer ${index + 1}`}
            />
            {pollType === "quiz" && (
              <input
                type="radio"
                name="correct"
                checked={correctAnswer === index}
                onChange={() => handleCorrectAnswerChange(index)}
              />
            )}
          </div>
        ))}
      </div>
      {pollType === "quiz" && (
        <p>
          <strong>Note: Select the radio button next to the correct answer</strong>
        </p>
      )}
      <button type="button" onClick={submitPoll}>Create Poll</button>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {pollCreated && <p style={{ color: "green" }}>Poll Created!</p>}
    </div>
  );
}

export default Poll;