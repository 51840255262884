import "react-quill/dist/quill.snow.css";

const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14,5,5,0,1,1,14,9"
    />
  </svg>
);
function boldUpdate() {
  const quill = this.quill;
  const range = quill.getSelection();
  if (range) {
    const isBold = quill.getFormat(range).bold;
    if (isBold) {
      quill.format("bold", false);
    } else {
      quill.format("bold", true);
    }
  }
}

function italicUpdate() {
  const quill = this.quill;
  const range = quill.getSelection();
  if (range) {
    const isItalic = quill.getFormat(range).italic;
    if (isItalic) {
      quill.format("italic", false);
    } else {
      quill.format("italic", true);
    }
  }
}

function underlineUpdate() {
  const range = this.quill.getSelection();
  console.log(range);
  if (range) {
    const isUnderline = this.quill.getFormat(range).underline;
    if (isUnderline) {
      this.quill.format("underline", false);
    } else {
      this.quill.format("underline", true);
    }
  }
}

function codeBlockUpdate() {
  const range = this.quill.getSelection();
  if (range) {
    const isCodeBlock = this.quill.getFormat(range)["code-block"];
    if (isCodeBlock) {
      this.quill.format("code-block", false);
    } else {
      this.quill.format("code-block", true);
    }
  }
}

function undoChange() {
  this.quill.history.undo();
}

function redoChange() {
  this.quill.history.redo();
}

export const modules = {
  toolbar: {
    container: "#toolbar2",
    handlers: {
      undo: undoChange,
      redo: redoChange,
      bold: boldUpdate,
      italic: italicUpdate,
      underline: underlineUpdate,
      "code-block": codeBlockUpdate,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
];

const Editor = () => {
  return (
    <div id="toolbar2">
      <span className="ql-formats">
        <select className="ql-font"></select>
        <select className="ql-size"></select>
        <select className="ql-header" defaultValue="3">
          <option value="1">Heading</option>
          <option value="2">Subheading</option>
          <option value="3">Normal</option>
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <button className="ql-indent" value="-1" />
        <button className="ql-indent" value="+1" />
      </span>
      <span className="ql-formats">
        <button className="ql-script" value="super" />
        <button className="ql-script" value="sub" />
        <button className="ql-blockquote" />
        <button className="ql-direction" />
      </span>
      <span className="ql-formats">
        <select className="ql-align" />
        <select className="ql-color" />
        <select className="ql-background" />
      </span>
      <span className="ql-formats">
        <button className="ql-link" />
        <button className="ql-image" />
        <button className="ql-video" />
      </span>
      <span className="ql-formats">
        <button className="ql-formula" />
        <button className="ql-code-block" />
        <button className="ql-clean" />
      </span>
      <span className="ql-formats">
        <button className="ql-undo">
          <CustomUndo />
        </button>
        <button className="ql-redo">
          <CustomRedo />
        </button>
      </span>
    </div>
  );
};

export default Editor;
