import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../App.css";

export default function FeedCommentBox(props) {
  const [currentComment, setCurrentComment] = useState("");
  const [media, setMedia] = useState(null);
  const [speech, setSpeech] = useState(null);
  const [error, setError] = useState("");
  const [isFirefox, setIsFirefox] = useState(false);
  const microphoneButtonRef = useRef(null);

  useEffect(() => {
    setCurrentComment("");
    setMedia(null);
  }, [props.activeAssetId]);

  useEffect(() => {
    setIsFirefox(typeof InstallTrigger !== "undefined");
  }, []);

  useEffect(() => {
    setupMicrophone();
  }, [isFirefox]);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches,
  );

  const commentOverlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
    display: props.isCommentModalOpen ? "block" : "none",
  };

  const commentModalStyle = {
    width: "92%",
    height: "42%",
    minHeight: "350px",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "16px",
    zIndex: 1000,
    display: props.isCommentModalOpen ? "block" : "none",
    fontFamily: "Acumin-RPro",
    fontSize: "16px",
  };

  const commentBoxStyle = {
    width: "100%",
    height: "58%",
    margin: "0 0 10px 0",
    background: "#E5E5E5",
    boxSizing: "border-box",
  };

  const commentTextBoxStyle = {
    width: "95%",
    height: "95%",
    border: "none",
    resize: "none",
    background: "#E5E5E5",
    outline: "none",
  };

  const inputMedia = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const inputTitle = {
    fontSize: isMobile ? "6px" : "14px",
  };

  const buttonStyle = {
    marginTop: isMobile ? "30px" : "60px",
  };

  const setupMicrophone = () => {
    if (isFirefox) {
      setError(
        "Speech recognition is not supported in Firefox. Please use a different browser.",
      );
      return;
    }

    const microphoneButton = microphoneButtonRef.current;

    const toggleBlack = () => {
      microphoneButton.style.filter = "none";
    };

    const toggleRed = () => {
      microphoneButton.style.filter =
        "invert(55%) sepia(100%) saturate(1249%) hue-rotate(332deg) brightness(95%) contrast(89%)";
    };

    let recognizing = false;
    let final_transcript = "";
    let ignore_onend;
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (!SpeechRecognition) {
      setError(
        "Speech recognition is not supported in this browser. Please use a different browser.",
      );
      return;
    }

    const recognition = new SpeechRecognition();
    recognition.interimResults = true;
    recognition.maxAlternatives = 1;

    recognition.onstart = () => {
      recognizing = true;
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error: ", event.error);
      if (
        event.error === "no-speech" ||
        event.error === "audio-capture" ||
        event.error === "not-allowed"
      ) {
        ignore_onend = true;
      }
    };

    recognition.onspeechend = () => {
      recognition.stop();
      recognizing = false;
      toggleBlack();
      if (ignore_onend || !final_transcript) {
        return;
      }
    };

    recognition.onresult = (event) => {
      const input_value = event.results[0][0].transcript;
      setCurrentComment(input_value);
    };

    const handleMicrophoneClick = () => {
      if (recognizing) {
        recognition.stop();
        toggleBlack();
        return;
      }
      final_transcript = "";
      recognition.lang = "en-US";
      recognition.start();
      toggleRed();
      ignore_onend = false;
    };

    microphoneButton.addEventListener("click", handleMicrophoneClick);

    return () => {
      microphoneButton.removeEventListener("click", handleMicrophoneClick);
    };
  };

  const handleMediaChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/tiff",
      "video/mp4",
      "video/ogg",
      "audio/mp3",
      "audio/mpeg",
      "audio/wav",
    ];
    if (file && allowedTypes.includes(file.type)) {
      setError("");
      setMedia(file);
    } else {
      setError("Invalid file type. Please select a valid media file.");
      setMedia(null);
    }
  };

  const handleTextChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["audio/mp3", "audio/wav", "video/mp4", "audio/mpeg"];
    if (file && allowedTypes.includes(file.type)) {
      setError("");
      setSpeech(file);
    } else {
      setError("Invalid file type. Please select a valid audio or video file.");
      setSpeech(null);
    }
  };

  const submitComment = async () => {
    if (props.activeAssetId && currentComment) {
      try {
        const commentData = {
          assetID: props.activeAssetId,
          feedID: props.feedID,
          comment: currentComment,
        };
        console.log(commentData);

        const response = await axios.post(
          "https://genz-staging.feeltiptop.com/api/comment",
          commentData,
          {
            headers: {
              Authorization: "Bearer " + props.token,
            },
          },
        );

        if (response.data.success) {
          console.log("Comment submitted successfully");
          props.onCommentSubmit();

          // Reset state and close modal
          setCurrentComment("");
          setMedia(null);
          setSpeech(null);
          props.setIsCommentModalOpen(false);
          props.setActiveAssetId(null);
        } else {
          console.error("Server responded with failure");
          setError("Failed to submit comment. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting comment:", error);
        setError(
          "An error occurred while submitting the comment. Please try again.",
        );
      }
    } else {
      setError("Please enter a comment before submitting.");
    }
  };

  const submitMedia = async () => {
    if (props.activeAssetId && media) {
      try {
        // Create a FormData object to handle file upload
        const formData = new FormData();
        formData.append("assetID", props.activeAssetId);
        formData.append("feedID", props.feedID);
        formData.append("media", media);

        for (let [key, value] of formData.entries()) {
          console.log(`${key}:`, value);
        }

        const response = await axios.post(
          "https://genz-staging.feeltiptop.com/api/media_response",
          formData,
          {
            headers: {
              Authorization: "Bearer " + props.token,
              "Content-Type": "multipart/form-data",
            },
          },
        );

        if (response.data.success) {
          console.log("Media submitted successfully");
          console.log(response);
          props.onCommentSubmit();

          // Reset state and close modal
          setCurrentComment("");
          setMedia(null);
          setSpeech(null);
          props.setIsCommentModalOpen(false);
          props.setActiveAssetId(null);
        } else {
          console.error("Server responded with failure");
          setError("Failed to submit media. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting media:", error);
        setError(
          "An error occurred while submitting the media. Please try again.",
        );
      }
    } else {
      setError("Please enter a media before submitting.");
    }
  };

  const submitSpeechToText = async () => {
    // Need to add disable to the button to prevent multi api calls
    if (props.activeAssetId && speech) {
      try {
        const formData = new FormData();
        formData.append("file", speech);

        const response = await axios.post(
          "https://stt.tiptopbest.com/upload",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          },
        );
        const { data } = response;
        if (data && data.transcript) {
          setCurrentComment(data.transcript);
          setError("Failed to process the file.");
        }
      } catch (error) {
        setError("Error processing the file.");
      }
    }
  };

  return (
    <>
      {/* Background Overlay */}
      {props.isCommentModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
          onClick={() => props.setIsCommentModalOpen(false)}
        />
      )}

      {/* Comment Container */}
      <div
        className={`fixed left-1/2 top-1/2 z-50 h-auto w-[92%] max-w-2xl -translate-x-1/2 -translate-y-1/2 transform rounded-md bg-white p-4 shadow-lg ${
          props.isCommentModalOpen ? "block" : "hidden"
        }`}
      >
        {/* Comment Box */}
        <div className="mb-5 h-[200px] w-full rounded-md bg-gray-100 p-4 shadow-inner">
          <textarea
            className="h-full w-full resize-none bg-transparent outline-none"
            value={currentComment}
            onChange={(e) => setCurrentComment(e.target.value)}
            placeholder="Type your comment here..."
          />
        </div>

        {/* Media Input */}
        <div className="mb-5 flex gap-2">
          <label
            htmlFor="mediaFile"
            className={`mr-2 ${isMobile ? "text-xs" : "text-sm"}`}
          >
            Select Media:
          </label>
          <input
            type="file"
            id="mediaFile"
            name="mediaFile"
            accept="image/*, video/mp4, audio/*"
            onChange={handleMediaChange}
            className="w-full max-w-xs rounded-lg border border-gray-300 p-2"
          />
          <button
            onClick={submitMedia}
            disabled={!media}
            className={`ml-3 h-10 rounded-lg px-4 py-2 font-medium transition-colors duration-300 ${
              !media
                ? "cursor-not-allowed bg-gray-300 text-gray-500"
                : "bg-emerald-600 text-white hover:bg-emerald-700"
            }`}
          >
            Upload
          </button>
        </div>

        {/* Speech to Text Input */}
        <div className="mb-5 flex items-center justify-between">
          <label
            htmlFor="textFile"
            className={`mr-2 font-medium text-gray-600 ${isMobile ? "text-xs" : "text-sm"}`}
          >
            Upload Audio/Video for Speech-to-Text:
          </label>
          <input
            type="file"
            id="textFile"
            name="textFile"
            accept="audio/*, video/mp4"
            onChange={handleTextChange}
            className="w-full max-w-xs rounded-lg border border-gray-300 p-2"
          />
          <button
            onClick={submitSpeechToText}
            disabled={!speech}
            className={`ml-3 h-10 rounded-lg px-4 py-2 font-medium transition-colors duration-300 ${
              !speech
                ? "cursor-not-allowed bg-gray-300 text-gray-500"
                : "bg-emerald-600 text-white hover:bg-emerald-700"
            }`}
          >
            Convert
          </button>
        </div>

        {/* Live Speech to Text */}
        <div className="mb-5 flex items-center">
          <label
            htmlFor="microphone"
            className={`mr-2 font-medium text-gray-600 ${isMobile ? "text-xs" : "text-sm"}`}
          >
            Live Speech-to-Text:
          </label>
          <button
            className="ml-3 flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 text-2xl shadow-md transition-all duration-300 hover:bg-gray-300"
            ref={microphoneButtonRef}
            id="microphone"
          >
            🎤
          </button>
        </div>

        {error && <div className="mt-2 text-sm text-red-600">{error}</div>}

        {/* Submit Button */}
        <button
          className={`h-12 w-full rounded-lg font-medium text-white transition-colors duration-300 ${
            !currentComment
              ? "cursor-not-allowed bg-gray-400"
              : "bg-emerald-600 hover:bg-emerald-700"
          }`}
          disabled={!currentComment}
          onClick={submitComment}
        >
          Post Comment
        </button>
      </div>
    </>
  );
}
