import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import backArrow from '../assets/images/arrow_back.svg';
import '../App.css'

const states = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California',
  'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
  'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
  'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
  'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri',
  'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
  'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio',
  'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
  'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
  'Virginia', 'Washington', 'Washington DC', 'West Virginia', 'Wisconsin', 'Wyoming'
];

function SelectState() {
  const [selectedState, setSelectedState] = useState('');

  const navigate = useNavigate();

  const { state } = useLocation();
  const {phoneNumber, userName, password, firstName, lastName, gender, dob, ethnicity} = state;

  const handleBackArrow = () => {
    navigate('/Ethnicity', {state: {phoneNumber, userName, password, firstName, lastName, gender, dob}});
  };

  const handleSubmit = () => {
    navigate('/selectInterests', { state: {phoneNumber, userName, password, firstName, lastName, gender, dob, ethnicity, selectedState: selectedState} });
  };

  return (
    <div className="signup-container">
      <div className="arrow-button">
          <img src={backArrow} alt="Logo" className="arrow" onClick={handleBackArrow}/>
      </div>
      <h2 className="title-verification">What state are you located in?</h2>

      <select
        className='date-input-field'
        value={selectedState}
        onChange={(e) => {
          setSelectedState(e.target.value);
        }}
      >
        <option value="">Select a state</option>
        {states.map((state, index) => (
          <option key={index} value={state}>
            {state}
          </option>
        ))}
      </select>
        <button
          style={{marginTop: '300px'}}
          className={`button ${selectedState === '' ? 'grey-background' : 'red-background'}`}
          disabled={selectedState === ''}
          onClick={handleSubmit}
        >
          Next
        </button>
    </div>
  );
}

export default SelectState;
