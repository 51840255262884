import { useLocation, Link} from 'react-router-dom';
import '../App.css';
import logo from '../assets/images/GenZ_Logo.svg';
import { useEffect} from 'react';

const STAR_COLOR = "#607196";
const STAR_SIZE = 3;
const STAR_MIN_SCALE = 0.2;
const OVERFLOW_THRESHOLD = 50;
const STAR_COUNT = (window.innerWidth + window.innerHeight) / 8;

function ThankYouPage() {
    const {state} = useLocation();
    const {text} = state;

    useEffect(() => {
        const canvas = document.querySelector("canvas"),
          context = canvas.getContext("2d");
    
        let scale = 1,
          width,
          height;
    
        let stars = [];
    
        let velocity = { x: 0, y: 0, tx: 0, ty: 0, z: 0.0045 };
    
        generate();
        resize();
        step();
    
        window.onresize = resize;
    
        function generate() {
          for (let i = 0; i < STAR_COUNT; i++) {
            stars.push({
              x: 0,
              y: 0,
              z: STAR_MIN_SCALE + Math.random() * (1 - STAR_MIN_SCALE),
            });
          }
        }
    
        function placeStar(star) {
          star.x = Math.random() * width;
          star.y = Math.random() * height;
        }
    
        function recycleStar(star) {
          let direction = "z";
    
          let vx = Math.abs(velocity.x),
            vy = Math.abs(velocity.y);
    
          if (vx > 1 || vy > 1) {
            let axis;
    
            if (vx > vy) {
              axis = Math.random() < vx / (vx + vy) ? "h" : "v";
            } else {
              axis = Math.random() < vy / (vx + vy) ? "v" : "h";
            }
    
            if (axis === "h") {
              direction = velocity.x > 0 ? "l" : "r";
            } else {
              direction = velocity.y > 0 ? "t" : "b";
            }
          }
    
          star.z = STAR_MIN_SCALE + Math.random() * (1 - STAR_MIN_SCALE);
    
          if (direction === "z") {
            star.z = 0.1;
            star.x = Math.random() * width;
            star.y = Math.random() * height;
          } else if (direction === "l") {
            star.x = -OVERFLOW_THRESHOLD;
            star.y = height * Math.random();
          } else if (direction === "r") {
            star.x = width + OVERFLOW_THRESHOLD;
            star.y = height * Math.random();
          } else if (direction === "t") {
            star.x = width * Math.random();
            star.y = -OVERFLOW_THRESHOLD;
          } else if (direction === "b") {
            star.x = width * Math.random();
            star.y = height + OVERFLOW_THRESHOLD;
          }
        }
    
        function resize() {
          scale = window.devicePixelRatio || 1;
    
          width = window.innerWidth * scale;
          height = window.innerHeight * scale;
    
          canvas.width = width;
          canvas.height = height;
    
          stars.forEach(placeStar);
        }
    
        function step() {
          context.clearRect(0, 0, width, height);
    
          update();
          render();
    
          requestAnimationFrame(step);
        }
    
        function update() {
          velocity.tx *= 0.96;
          velocity.ty *= 0.96;
    
          velocity.x += (velocity.tx - velocity.x) * 0.8;
          velocity.y += (velocity.ty - velocity.y) * 0.8;
    
          stars.forEach((star) => {
            star.x += velocity.x * star.z;
            star.y += velocity.y * star.z;
    
            star.x += (star.x - width / 2) * velocity.z * star.z;
            star.y += (star.y - height / 2) * velocity.z * star.z;
            star.z += velocity.z;
    
            if (
              star.x < -OVERFLOW_THRESHOLD ||
              star.x > width + OVERFLOW_THRESHOLD ||
              star.y < -OVERFLOW_THRESHOLD ||
              star.y > height + OVERFLOW_THRESHOLD
            ) {
              recycleStar(star);
            }
          });
        }
    
        function render() {
          stars.forEach((star) => {
            context.beginPath();
            context.lineCap = "round";
            context.lineWidth = STAR_SIZE * star.z * scale;
            context.globalAlpha = 0.5 + 0.5 * Math.random();
            context.strokeStyle = STAR_COLOR;
    
            context.beginPath();
            context.moveTo(star.x, star.y);
    
            var tailX = velocity.x * 2,
              tailY = velocity.y * 2;
    
            if (Math.abs(tailX) < 0.1) tailX = 0.5;
            if (Math.abs(tailY) < 0.1) tailY = 0.5;
    
            context.lineTo(star.x + tailX, star.y + tailY);
    
            context.stroke();
          });
        }
      }, []);
    
    return (
        <div className="landing-page">
            <img style={{}} src={logo} width='300px' height='auto'></img>
            <h2 style={{lineHeight:'63px', marginTop: '30px', fontWeight: '700',fontSize: '52.5px', marginBottom: '0px'}}>thank you</h2>
            <h2 style={{lineHeight:'63px', marginBottom: '30px', fontWeight: '382',fontSize: '38px'}}>{text}</h2>
            <Link to="/" style={{ textDecoration: 'none' }} className="centered-text-2">Back to <span className="highlight">Home</span></Link>
            <canvas></canvas>
        </div>
    );
}

export default ThankYouPage;

