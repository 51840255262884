

export default function AmbassadorProfileBox(props){

    const ambassadorProfileContainerStyle = {
        display: "flex",
        padding: "15px",
        borderRadius: '6px',
        width: "400px",
        background: 'var(--Light-gray, #F8F7F7)'
    }

    const profileContentStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px'
    }
    const ambassadorProfilePictureStyle = {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        borderRadius: '8px',
        width: "60px",
        height: "60px"
    }

    const profileSummaryContainerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "3px",
    }

    const profileNameStyle = {
        color: '#000',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '400',
        marginBottom: '0px',
    }

    const industryBoxContainerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        width: '370px',
        gap: '10px',
        padding: '0px'
    }

    const industryBoxStyle = {
        borderRadius: '20px',
        border: '0px solid var(--Black, #000)',
        background: 'var(--pink, #F6DADA)',
        display: 'flex',
        minHeight: '24px',
        flex: '0 1 44px',
        padding: '6px 20px',
        justifyContent: 'center',
        alignItems: 'center',

    }

    const industryBoxTextStyle = {
        color: 'var(--Black, #000)',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '24px',
        margin: '0px 0px 0px 0px',
    }
    const industryMap = (name) => 
        <div style={industryBoxStyle}>
            <p style={industryBoxTextStyle}>{name}</p>
        </div>
        
    return(
        <div style={ambassadorProfileContainerStyle}>
            <div style={profileContentStyle}>
                <div style={{display: 'flex', alignItems: 'center', gap: '13px'}}>
                    <div style={ambassadorProfilePictureStyle}>
                        <img src={"https://genz-staging.feeltiptop.com/" + props.profilePicture}  style={{maxHeight: '100%', maxWidth: '100%', borderRadius: '5%'}}>
                        </img>
                    </div>
                    <div style={profileSummaryContainerStyle}>
                        <p style={profileNameStyle}>{props.name}</p>
                        <p style={{...profileNameStyle, fontSize: '16px', color: '#565656'}}>{props.demographics}</p>
                    </div>
                </div>
                <ul style={industryBoxContainerStyle}>
                    {props.industries.map(industryMap)}
                </ul>
            </div>
        </div>
    )
}