import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import backArrow from '../assets/images/arrow_back.svg';
import '../App.css'

function Ethnicity() {
  const [selectedEthnicity, setSelectedEthnicity] = useState('');
  const navigate = useNavigate();

  const { state } = useLocation();
  const { phoneNumber, userName, password, firstName, lastName, gender, dob} = state;

  const ethnic = [
    'American Indian or Alaska Native',
    'Asian',
    'Black or African American',
    'Caucasian or White',
    'Hispanic or Latinx',
    'Middle Eastern or North African',
    'Native Hawaiian or other Pacific Islander',
    'Prefer not to say'
  ];

  const handleBackArrow = () => {
    navigate('/DateOfBirth', {state: {phoneNumber, userName, password, firstName, lastName, gender, dob }});
  };

  const handleSubmit = () => {
    navigate('/SelectState', { state: {phoneNumber, userName, password, firstName, lastName, gender, dob, ethnicity: selectedEthnicity } });
  };

  return (
    <div className="signup-container">
      <div className="arrow-button">
          <img src={backArrow} alt="Logo" className="arrow" onClick={handleBackArrow}/>
      </div>
      <h2 className="title-gender">What's your ethnicity?</h2>
      <p className="gender-description-text">Select One</p>
      {ethnic.map((ethnic) => {
        return (
          <div key={ethnic} className='gender-input-container'>
            <label htmlFor={ethnic}>
              {ethnic}
            </label>
            <input
              type="radio"
              id={ethnic}
              name="ethnic"
              value={ethnic}
              checked={selectedEthnicity === ethnic}
              onChange={() => setSelectedEthnicity(ethnic)}
            />
          </div>
        );
      })}


        <button
          style={{marginTop: '20px'}}
          className={`button ${selectedEthnicity === '' ? 'grey-background' : 'red-background'}`}
          disabled={selectedEthnicity === ''}
          onClick={handleSubmit}
        >
          Next
        </button>
    </div>
  );
}

export default Ethnicity;