import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import backArrow from '../assets/images/arrow_back.svg';
import '../App.css'
import axios from 'axios';

function LoginVerification(props) {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [isVerified, setIsVerified] = useState(false)
  const [resendAvailable, setResendAvailable] = useState(true);
  const [resendRefreshTime, setresendRefreshTime] = useState(30);
  const navigate = useNavigate();

  const { state } = useLocation();
  const { phoneNumber, initialCode, feedID} = state;
  const [veriCode, setVeriCode] = useState(initialCode);

  const getAccessToken = async () => {
    try {
      console.log("sent pN: "+ phoneNumber)
      const response = await axios.post('https://genz-staging.feeltiptop.com/api/token', { phoneNumber });
      // console.log("response token: " + response.data.access_token)
      props.setToken(response.data.access_token);
      return response.data.access_token; // Indicates successful token retrieval
    } catch (error) {
      console.error('Error:', error);
      return; // Indicates an error occurred
    }
  };

  const handleBackToLogin = () => {
    navigate('/login');
  };

  const handleInputChange = (value) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if ((value === '' || re.test(value)) &&  value.length <= 6) {
       setCode(value);
       setError('');
    }
  };

  useEffect(() => {
    if (!resendAvailable && resendRefreshTime > 0) {
        const timer = resendRefreshTime > 0 && setInterval(() => setresendRefreshTime(resendRefreshTime - 1), 1000);
        return () => clearInterval(timer);
    } else{
      setResendAvailable(true);
      setresendRefreshTime(30);
    }
  }, [resendRefreshTime, resendAvailable]);


  
  const fetchData = async (token) => {
    // if (!props.token || !phoneNumber || !feedID) return;    
    try {
      // getAccessToken();
      // if (access_token) {
      //   props.setToken(access_token);
      // }
      console.log("Token: " + props.token)
      console.log(phoneNumber, feedID)
      const feedSessionResponse = await axios.post('https://genz-staging.feeltiptop.com/api/getFeedSession', {
        phoneNumber,
        feedID,
      }, {
        headers: { Authorization: "Bearer " + token },

      });
  
      const { feedID: sessionFeedID, userID, feedStartTime} = feedSessionResponse.data;
  
      const countResponse = await axios.get(`https://genz-staging.feeltiptop.com/api/countUserStatus?feedId=${sessionFeedID}`);
      const userStatusResponse = await axios.get(`https://genz-staging.feeltiptop.com/api/getUserStatus`, {
        params: {
          feedID: sessionFeedID,
          userID: userID
        },
        headers: { Authorization: "Bearer " + token },

      });
      const { MaxStarted, MaxCompleted } = countResponse.data.statusCheck;
      console.log(userStatusResponse.data.UserStatus);
      if (userStatusResponse.data.UserStatus === "Completed") {
        alert("User has already completed the feed session.");
        return;
      }
      if ((MaxStarted || MaxCompleted) && userStatusResponse.data.UserStatus !== 'Started' ) {
        alert("Feed is already full");
        return;
      }
  

  
      const changeFeedUserStatusResponse = await axios.post('https://genz-staging.feeltiptop.com/api/changeFeedUserStatus', {
        feedID: sessionFeedID,
        userID,
        feedStartTime
      }, {
        headers: { Authorization: "Bearer " + token },

      });
  
      const { message, feedStartTime: updatedFeedStartTime } = changeFeedUserStatusResponse.data;
  
      // if (message === "already-submitted") {
      //   alert("User has already completed the feed session.");
      //   return;
      // }
  
      navigate('/feed', {
        state: {
          userID,
          feedID: sessionFeedID,
          sessionEndTime: feedSessionResponse.data.sessionEndTime,
          feedStartTime: updatedFeedStartTime
        }
      });
  
    } catch (error) {
      console.error(error);
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        const statusCode = error.response.status;
        console.error("Server Response Data:", error.response.data);
        switch(statusCode) {
          case 400:
            alert("Bad Request - The server could not understand the request due to invalid syntax.");
            break;
          case 401:
            alert("Unauthorized - Authentication is required and has failed or has not yet been provided.");
            break;
          case 403:
            alert("Forbidden - You do not have the necessary permissions to access this resource.");
            break;
          case 404:
            alert("An error has occured make sure that the link you have is correct.");
            break;
          case 500:
            alert("Internal Server Error - An unexpected condition was encountered on the server.");
            break;
          default:
            console.log(`Error ${statusCode}: Something went wrong, please try again later.`);
        }
      } else if (error.request) {
        // The request was made but no response was received
        alert("No response was received from the server. Please check your network connection.");
      } else {
        // Something happened in setting up the request that triggered an error
        alert("An error occurred while setting up the request.");
      }
    }
  };
    
    

  const handleResetCode = () => {
    axios.post('https://genz-staging.feeltiptop.com/api/phoneVerification', {phoneNumber:phoneNumber})
    .then((response) => {
      setVeriCode(response.data.code);
      setResendAvailable(false);
    })
    .catch((error) => {
      console.error(error);
    });

  }

  const handleSubmit = async () => {
    if (code === veriCode) {
      setIsVerified(true);
      const token = await getAccessToken();
      if (token) {
        await fetchData(token); // Pass the token as an argument
      }
    } else {
      setError('Oops! The code you entered is incorrect.');
    }
  };

  return (
    <div className="signup-container">
      <div className="arrow-button">
          <img src={backArrow} alt="Logo" className="arrow" onClick={handleBackToLogin} />
      </div>
      <h2 className="title-verification">Enter code</h2>

      <p className="phoneVerificationInstructions">
        Please enter the 6-digit verification code we sent to {phoneNumber}.
      </p>

      <div>
          <input
            style={{textAlign: 'center', letterSpacing:'8px'}}
            type="text"
            inputMode="numeric"
            className={`input-field`}
            value={code}
            onChange={(e) => handleInputChange(e.target.value)}
          />
      </div>
      {error && (
        <div className="error-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 8V12" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 16H12.01" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <p className='error'>{error}</p>
        </div>
      )}
      <div>
        {resendAvailable 
        ? <p className='resend-code' onClick={() => handleResetCode()}>
            Resend Code
          </p> 
        : null
        }
      </div>
      <div> 
        { resendAvailable 
        ? null 
        : <div className='resend-refresh'>
            <p>&#x2713; Code resent</p>
            <p>Resend code available in {resendRefreshTime} seconds</p>
          </div>
        }
      </div>

      <button
        className={`button ${code.length === 6 ? 'red-background' : 'grey-background'}`}
        disabled={code.length === 6 ? false : true}
        onClick={handleSubmit}
      >
        Verify
      </button>
    </div>
  );
}

export default LoginVerification;
