import React from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";

function FeedStart() {
  const navigate = useNavigate();

  const goToPage1 = () => {
    navigate("/NewFeedConstruction");
  };

  const goToPage2 = () => {
    navigate("/FeedSelect");
  };

  return (
    <div className="container">
      <button className="button" onClick={goToPage1}>
        {" "}
        Create new Feed from scratch{" "}
      </button>
      <button className="button" onClick={goToPage2}>
        {" "}
        Choose from existing Feeds{" "}
      </button>
    </div>
  );
}

export default FeedStart;
