import { useState, useEffect } from "react";

const SolrQueryDate = ({ setDateQuery, setQuizData }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const updateDate = (e) => {
    const id = e.target.id; 
    if (id === "start-date") {
      setStartDate(e.target.value);
    }
    if (id === "end-date") {
      setEndDate(e.target.value);
    }
  };

  const updateDateQuery = () => {
    if (startDate && endDate) {
      const formattedStartDate = new Date(startDate).toISOString();
      const formattedEndDate = new Date(endDate).toISOString();
      const formattedTimeFrame = `tttime:[${formattedStartDate}%20TO%20${formattedEndDate}]`;
      setDateQuery(formattedTimeFrame);
      setQuizData((prevState) => ({
        ...prevState,
        timeFrame: formattedTimeFrame
      }))
      console.log(formattedTimeFrame);
    }
  };

  useEffect(() => {
    updateDateQuery(); 
  }, [startDate, endDate]); 

  return (
    <div>
      <label htmlFor="start-date">Start Date</label>
      <input
        type="date"
        id="start-date"
        value={startDate}
        onChange={updateDate}
        required
        placeholder="Input start date"
      />
      <br />

      <label htmlFor="end-date">End Date</label>
      <input
        type="date"
        id="end-date"
        value={endDate}
        onChange={updateDate}
        required
        placeholder="Input end date"
      />
    </div>
  );
};

export default SolrQueryDate;