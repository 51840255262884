import React from "react";

function QuizDisplay({ type, selections = [], checkAnswer }) {
  if (type.includes("True or False")) {
    return (
      <>
        <li
          className="quiz-container-ol-choice"
          value="True"
          onClick={checkAnswer}
        >
          True
        </li>
        <li
          className="quiz-container-ol-choice"
          value="False"
          onClick={checkAnswer}
        >
          False
        </li>
      </>
    );
  } else if (type.includes("Fill in the blank")) {
    return (
      <input
        type="text"
        className="quiz-container-ol-choice"
        placeholder="Enter Answer"
      />
    );
  } else {
    return (
      <>
        {Object.entries(selections).map(([key, value]) => (
          <li
            key={key}
            className="quiz-container-ol-choice"
            value={value}
            onClick={checkAnswer}
          >
            {value}
          </li>
        ))}
      </>
    );
  }
}

export default QuizDisplay;
