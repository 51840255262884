// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
    height: 100%;
    margin: 0;
}

.container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;     /* Center vertically */
    height: 100vh;           /* Full viewport height */
    flex-direction: column;  /* Arrange buttons vertically */
}

.button {
    margin: 10px;            /* Space between buttons */
}

`, "",{"version":3,"sources":["webpack://./src/routes/Home.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,aAAa;IACb,uBAAuB,EAAE,wBAAwB;IACjD,mBAAmB,MAAM,sBAAsB;IAC/C,aAAa,YAAY,yBAAyB;IAClD,sBAAsB,GAAG,+BAA+B;AAC5D;;AAEA;IACI,YAAY,aAAa,0BAA0B;AACvD","sourcesContent":["body, html {\n    height: 100%;\n    margin: 0;\n}\n\n.container {\n    display: flex;\n    justify-content: center; /* Center horizontally */\n    align-items: center;     /* Center vertically */\n    height: 100vh;           /* Full viewport height */\n    flex-direction: column;  /* Arrange buttons vertically */\n}\n\n.button {\n    margin: 10px;            /* Space between buttons */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
