import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Editor, { modules, formats } from "./Editor";

function Text({ textContent, setTextContent }) {
  const handleChange = (value) => {
    setTextContent(value);
  };

  return (
    <div className="text-editor">
      <Editor />
      <ReactQuill
        theme="snow"
        value={textContent}
        onChange={handleChange}
        placeholder={"Write something..."}
        modules={modules}
        formats={formats}
        style={{ height: "50vh" }}
      />
    </div>
  );
}

export default Text;
