import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../assets/images/GenZ_Logo.svg";
import { encrypttoHEX } from "./encodedecode.js";
import FeedCommentBox from "../components/FeedCommentBox.js";
import commentImage from "../assets/reaction_images/Comment.svg";
import commentImageClicked from "../assets/reaction_images/CommentClicked.svg";
import PollComponent from "../components/PollComponent.js";
import EmbedComponent from "../components/EmbedComponent.js";
import MediaComponent from "../components/MediaComponent.js";

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    border: "1px solid #ccc",
  },
  leftContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    borderRight: "1px solid #ccc",
    overflowY: "auto",
  },
  rightContainer: {
    flex: 1,
    overflowY: "auto",
    padding: "10px",
  },
  innerContainer: {
    padding: "10px",
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  assetList: {
    flex: 1,
    marginTop: "10px",
    overflowY: "auto",
  },
  borderLine: {
    height: "1px",
    backgroundColor: "#ccc",
    margin: "10px 0",
  },
  fullPost: {
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    marginBottom: "10px",
  },
  fullCompanyHeader: {
    display: "flex",
    flexDirection: "column",
  },
  postHeader: {
    display: "flex",
    alignItems: "center",
  },
  companyIcon: {
    marginRight: "10px",
  },
  companyIconImage: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
  },
  companyName: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  caption: {
    fontSize: "14px",
    marginTop: "5px",
  },
  contentContainer: {
    marginTop: "10px",
  },
  mediaAndLinkContainer: {
    marginBottom: "10px",
  },
  openLink: {
    textDecoration: "none",
    color: "#007bff",
    display: "flex",
    alignItems: "center",
  },
  openLinkText: {
    marginRight: "5px",
  },
  openLinkArrow: {
    width: "16px",
    height: "16px",
  },
};

const FeedSelect = (props) => {
  const [feedIDs, setFeedIDs] = useState([]);
  const [selectedFeedID, setSelectedFeedID] = useState(null);
  const [error, setError] = useState("");
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("https://genz-staging.feeltiptop.com/api/getAllFeeds", {
        headers: {
          Authorization: "Bearer " + props.token,
        },
      })
      .then((response) => {
        console.log("hello");
        setFeedIDs(response.data);
      })
      .catch((error) => {
        console.error(error);
        setError("Error fetching feed IDs");
      });
  }, [props.token]);

  const handleFeedChange = (event) => {
    setSelectedFeedID(event.target.value);
  };

  const TextComponent = ({ assetContent }) => (
    <div dangerouslySetInnerHTML={{ __html: assetContent.html }} />
  );

  const renderAssetContent = (post) => {
    const assetContent = JSON.parse(post.AssetContent);

    switch (assetContent.assetType) {
      case "poll":
        return <PollComponent assetContent={assetContent} />;
      case "text":
        return <TextComponent assetContent={assetContent} />;
      case "media":
        console.log(assetContent.media_link);
        return <MediaComponent mediaLink={assetContent.mediaContent} />;
      default:
        return <p>Unsupported asset type: {assetContent.assetType}</p>;
    }
  };

  useEffect(() => {
    // If currentFeedID is not 0 (i.e., it is a valid feed ID), make a GET request to the backend to fetch posts for that feed
    if (selectedFeedID !== 0) {
      axios
        .get("https://genz-staging.feeltiptop.com/api/getPosts", {
          // Include the feed ID as a query parameter in the request
          params: { feedID: selectedFeedID },
          // Include an Authorization header with a bearer token for authentication
          headers: { Authorization: "Bearer " + props.token },
        })
        .then((response) => {
          // If the server response contains an access token, update the token state
          response.data.access_token &&
            props.setToken(response.data.access_token);
          // Append the new posts to the existing posts
          setPosts(response.data);
        })
        .catch((error) => {
          // If there's an error fetching the posts, set the error state
          setError(error);
        });
    }
  }, [selectedFeedID]);

  const handleSubmit = () => {
    const submitFeedID = selectedFeedID;
    navigate("/NewFeedConstruction", {
      state: {
        feedID: selectedFeedID,
      },
    });
  };

  const renderedPosts = posts.map((asset) => (
    <div key={asset.AssetID}>
      <div style={{ height: "50px" }}></div>
      {/* <hr style={{ borderTop: '1px solid light gray', width: '100%' }} /> */}

      <div className="fixed-footer">
        <div className="footer-content">
          <div className="footer-logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className="footer-timer"></div>
          <div className="footer-svg">{/* SVG Content */}</div>
        </div>
      </div>
      <div style={styles.fullPost}>
        <div style={styles.fullCompanyHeader}>
          <div style={styles.postHeader}>
            <div style={styles.companyIcon}>
              {asset.BrandProfilePicture ? (
                <img
                  src={`https://genz-staging.feeltiptop.com/${posts.BrandProfilePicture}`}
                  alt="Company Icon"
                  style={styles.companyIconImage}
                />
              ) : (
                <div
                  alt="Blank Company Icon"
                  style={{
                    ...styles.companyIconImage,
                    backgroundImage:
                      "url(https://upload.wikimedia.org/wikipedia/commons/a/a7/Blank_image.jpg)",
                  }}
                />
              )}
            </div>
            <p style={styles.companyName}>{asset.BrandName}</p>
          </div>
          <p style={styles.caption}>{asset.Caption}</p>
        </div>
        <div style={styles.contentContainer}>
          <div style={styles.mediaAndLinkContainer}>
            {renderAssetContent(asset)}
          </div>
          {asset.OpenLink && (
            <a
              href={asset.OpenLink}
              target="_blank"
              style={styles.openLink}
              onClick={(e) => {
                e.preventDefault();
                {/* clickLink(currentUserID, selectedFeedID, asset.AssetID); No function associated or variable called clickLink and currentUserID respectively so commented out */}
                window.open(asset.OpenLink, "_blank");
              }}
            >
              <span style={styles.openLinkText}>Open Link</span>
              <div style={styles.openLinkArrow}>
                {/* <SharelinkIcon /> Might be a removed/deprecated feature + giving errors on npm start */}
              </div>
            </a>
          )}
        </div>
      </div>
      <div style={{ height: "25px" }}></div>
    </div>
  ));

  return (
    <div style={styles.container}>
      <div style={styles.leftContainer}>
        <h1>Feed List</h1>
        {feedIDs.length > 0 ? (
          <form onSubmit={handleSubmit}>
            {feedIDs.map((feed, index) => (
              <div key={index}>
                <input
                  type="radio"
                  id={`feed-${feed.FeedID}`}
                  name="feed"
                  value={feed.FeedID}
                  onChange={handleFeedChange}
                />
                <label htmlFor={`feed-${feed.FeedID}`}>
                  {feed.FeedID} - {feed.name}
                </label>
              </div>
            ))}
            <button type="submit">Submit</button>
          </form>
        ) : (
          <p>No feeds available</p>
        )}
      </div>

      <div style={styles.rightContainer}>{renderedPosts}</div>
    </div>
  );
};

export default FeedSelect;
