import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import backArrow from '../assets/images/arrow_back.svg';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import CryptoJS from 'crypto-js';
import '../App.css';

function EnterPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const { state } = useLocation();
  const { phoneNumber, userName } = state;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleBackArrow = () => {
    navigate('/enterusername', { state: { phoneNumber } });
  };

  const handleSubmit = () => {
    if (checkPassword(password, confirmPassword)) {
      const hashedPassword = CryptoJS.SHA256(password).toString();
      navigate('/entername', { state: { phoneNumber, userName, password: hashedPassword } });
    } else if (password !== confirmPassword) {
      setError("Passwords do not match.");
    } else {
      setError("Password does not meet the criteria. Please ensure it is at least 8 characters long and contains at least one lowercase letter, one uppercase letter, one number, and one special character.");
    }
  };

  const checkPassword = (pw, pw2) => {
    const decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    return pw !== '' && decimal.test(password) && pw === pw2;
  };

  return (
    <div className="signup-container">
      <div className="arrow-button">
        <img src={backArrow} alt="Logo" className="arrow" onClick={handleBackArrow} />
      </div>
      <h2 className="title-signup">Enter your password</h2>

      <div className="password-input-container">
        <input
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input-field-password"
        />
        <button onClick={togglePasswordVisibility} className="toggle-password-button">
          {showPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
        </button>
      </div>

      <div>
        <input
          type="password"
          placeholder="Confirm Password"
          className="input-fields"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>

      {error && (
        <div className="error-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 8V12" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 16H12.01" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <p className='error'>{error}</p>
        </div>
      )}

      <button
        style={{ marginTop: '200px' }}
        className={`button ${password.length > 0 && confirmPassword.length > 0 ? 'red-background' : 'grey-background'}`}
        disabled={password.length === 0 || confirmPassword.length === 0}
        onClick={handleSubmit}
      >
        Next
      </button>
    </div>
  );
}

export default EnterPassword;
