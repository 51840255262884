import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import backArrow from '../assets/images/arrow_back.svg';
import axios from 'axios';
import '../App.css';

function formatPhoneNumber(inputValue) {
  const cleaned = inputValue.replace(/\D/g, '');
  const match = cleaned.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  
  if (match) {
    return match.slice(1, 4).filter(Boolean).join('-');
  } else {
    return '';
  }
}

const OldSignUp = (props) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [buttonClicked, setButtonClicked] = useState(false);
  const navigate = useNavigate();
  document.title = "GenZ Sign Up"; 
  const handleSignUp = () => {
    if (!buttonClicked) {
      setButtonClicked(true);
      axios.post('https://genz-staging.feeltiptop.com/api/checkPhoneNumber', {phoneNumber: phoneNumber})
      .then((response) => {
        if (!response.data.exists) {
          //👇🏻 Send the SMS by calling the backend

          /*
          axios.post('https://genz-staging.feeltiptop.com/api/phoneVerification', {phoneNumber: phoneNumber})
          .then((response) => {
            navigate('/verifyphonenumber', { state: { phoneNumber: phoneNumber, initialCode: response.data.code} });
          })
          */

          axios.post('https://genz-staging.feeltiptop.com/api/token', {passCode: '4zPoQ5KAkR'})
          .then(response => {
            props.setToken(response.data.access_token)
            navigate('/enterusername', {state: {phoneNumber: phoneNumber}});
          })
          .catch(error => {
            console.error('Error:', error);
            setButtonClicked(false);
          });
         
        } else {
          setError("Phone Number already exists!")
          setButtonClicked(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && phoneNumber.length === 12) {
      handleSignUp();
    }
  };

  return (
    <div className="signup-container">
      <div className="arrow-button">
        <Link to="/">
          <img src={backArrow} alt="Logo" className="arrow"/>
        </Link>
      </div>
      <div className="USA-HEADER">
        <h2 className="title-signup">Enter phone number</h2>
        <div className="UsaOnly"><span>&#40;</span>USA only<span>&#41;</span></div>
      </div>

      <input 
        type="tel"
        placeholder="123-456-7890"
        className="input-field"
        value={phoneNumber}
        onChange={(e) => {
          const formattedPhoneNumber = formatPhoneNumber(e.target.value);
          setPhoneNumber(formattedPhoneNumber);
        }}
        onKeyUp={handleKeyPress}
      />

      {error && (
        <div className="error-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 8V12" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 16H12.01" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <p className='error'>{error}</p>
        </div>
      )}

      <button
        className={`button ${phoneNumber.length !== 12 || buttonClicked ? 'grey-background' : 'red-background'}`}
        disabled={phoneNumber.length !== 12 || buttonClicked}
        onClick={handleSignUp}
      >
        Sign Up
      </button>
    </div>
  );
}

export default OldSignUp;
