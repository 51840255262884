import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import logo from "../assets/images/GenZ_Logo.svg";
import { ReactComponent as SharelinkIcon } from "../assets/svg/sharelink-icon.svg";
import logoutIcon from "../assets/svg/logout-icon.svg";
import TextComponent from "../components/TextComponent";
import FeedCommentBox from "../components/FeedCommentBox";
import commentImage from "../assets/reaction_images/Comment.svg";
import commentImageClicked from "../assets/reaction_images/CommentClicked.svg";
import PollComponent from "../components/PollComponent";
import MediaComponent from "../components/MediaComponent";

import "./FeedStyling.css";

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

const Feed = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();

  const [error, setError] = useState("");
  const [currentComment, setCurrentComment] = useState("");
  const [timer, setTimer] = useState("0:00");
  const [sessionEndTime, setSessionEndTime] = useState("");
  const [feedStartTime, setFeedStartTime] = useState("");

  const [currentFeedID, setCurrentFeedID] = useState(0);
  const [currentUserID, setCurrentUserID] = useState(0);

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isFeedComplete, setIsFeedComplete] = useState(false);
  const [feedIdInUrl, setFeedIdInUrl] = useState(false);

  const [posts, setPosts] = useState([]);
  const [feedIdQueue, setFeedIdQueue] = useState([]);

  const [superlikedAssets, setSuperlikedAssets] = useState({});
  const [sharedAssets, setSharedAssets] = useState({});
  const [commentSubmitted, setCommentSubmitted] = useState({});

  const [activeAssetId, setActiveAssetId] = useState(null);
  const [seen, setSeen] = useState(new Set());

  const [offSet, setOffSet] = useState(0);
  const [endReached, setEndReached] = useState(false);

  document.title = "GenZ Feed";

  const handleCommentSubmit = (assetId) => {
    // Update the state to indicate that a comment has been submitted for this assetId
    setCommentSubmitted((prev) => ({ ...prev, [assetId]: true }));
  };

  const Timer = ({ feedStartTime }) => {
    useEffect(() => {
      const updateTimer = () => {
        const fstartTime = new Date(feedStartTime);
        const currentTime = new Date();

        const fstartTimeUtc = Date.UTC(
          fstartTime.getFullYear(),
          fstartTime.getMonth(),
          fstartTime.getDate(),
          fstartTime.getHours(),
          fstartTime.getMinutes(),
          fstartTime.getSeconds(),
        );

        const currentTimeUtc = Date.UTC(
          currentTime.getFullYear(),
          currentTime.getMonth(),
          currentTime.getDate(),
          currentTime.getHours(),
          currentTime.getMinutes(),
          currentTime.getSeconds(),
        );

        const difference = currentTimeUtc - fstartTimeUtc;
        if (difference >= 0) {
          const minutes = Math.floor(difference / 60000);
          const seconds = Math.floor((difference % 60000) / 1000);
          setTimer(
            (minutes < 10 ? "0" : "") +
              minutes +
              ":" +
              (seconds < 10 ? "0" : "") +
              seconds,
          );
        }
      };

      const timerInterval = setInterval(updateTimer, 1000);

      return () => clearInterval(timerInterval);
    }, [feedStartTime]);
  };

  // Function to fetch posts from the backend
  useEffect(() => {
    const logo = document.querySelector(".logo");
    if (logo) {
      logo.style.visibility = "hidden";
    }

    return () => {
      if (logo) {
        logo.style.visibility = "hidden";
      }
    };
  }, []);

  useEffect(() => {
    // If currentFeedID is not 0 (i.e., it is a valid feed ID), make a GET request to the backend to fetch posts for that feed
    if (currentFeedID !== 0) {
      axios
        .get("https://genz-staging.feeltiptop.com/api/getPosts", {
          // Include the feed ID as a query parameter in the request
          params: { feedID: currentFeedID },
          // Include an Authorization header with a bearer token for authentication
          headers: { Authorization: "Bearer " + props.token },
        })
        .then((response) => {
          // If the server response contains an access token, update the token state
          response.data.access_token &&
            props.setToken(response.data.access_token);
          // Append the new posts to the existing posts
          console.log("response data: ", response.data);
          const filterAssetContent = response.data.filter(
            (post) => !seen.has(post.AssetID),
          );

          setPosts((prevPosts) => [...prevPosts, ...filterAssetContent]);
          // setPosts(...response.data);

          console.log("post ", posts);
        })
        .catch((error) => {
          // If there's an error fetching the posts, set the error state
          setError(error);
        });
    }
  }, [currentFeedID]);

  useEffect(() => {
    console.log(state);
    if (state) {
      console.log("hi");
      console.log(state);
      setCurrentFeedID(state.feedID);
      setCurrentUserID(state.userID);
      setSessionEndTime(state.sessionEndTime);
      setFeedStartTime(state.feedStartTime);
      setFeedIdInUrl(true);
    } else {
      if (
        searchParams.has("feedid") &&
        searchParams.has("userid") &&
        searchParams.has("sessionendtime")
      ) {
        setCurrentFeedID(searchParams.get("feedid"));
        setCurrentUserID(searchParams.get("userid"));
        setSessionEndTime(searchParams.get("sessionendtime"));
        setFeedStartTime(searchParams.get("feedstarttime"));
        setFeedIdInUrl(true);
      } else {
        console.log("here");
        fetchUniqueFeedSession();
      }
    }
  }, [state, searchParams, currentFeedID]);

  const handleLogOut = () => {
    axios
      .post(
        "https://genz-staging.feeltiptop.com/api/logout",
        {},
        {
          headers: { Authorization: `Bearer ${props.token}` },
        },
      )
      .then((response) => {
        props.removeToken();
        localStorage.removeItem("authToken");
        navigate("/login");
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

  const clickLink = async (userId, feedId, assetId) => {
    try {
      const response = await axios.post(
        "https://genz-staging.feeltiptop.com/api/linkclick",
        {
          assetID: assetId,
          userID: userId,
          feedID: feedId,
        },
        {
          headers: { Authorization: "Bearer " + props.token },
        },
      );
      response.data.access_token && props.setToken(response.data.access_token);
      if (response.data.success) {
        console.log("Link click submitted successfully");
      } else {
        console.error("Server responded with failure");
      }
    } catch (error) {
      console.error("Error submitting link click:", error);
    }
  };

  const renderAssetContent = (post) => {
    const assetContent = JSON.parse(post.AssetContent);

    switch (assetContent.assetType) {
      case "poll":
        return (
          <PollComponent
            assetContent={assetContent}
            userID={currentUserID}
            feedID={currentFeedID}
            activeAssetId={post.AssetID}
            token={props.token}
            setToken={props.setToken}
          />
        );
      case "text":
        return <TextComponent assetContent={assetContent} />;
      case "media":
        return <MediaComponent mediaLink={assetContent.mediaContent} />;
      default:
        return <p>Unsupported asset type: {assetContent.assetType}</p>;
    }
  };

  //note to sel: ranme fetchFeedIds -> fetchAssetIDS

  const fetchFeedIDS = async () => {
    if (endReached) {
      return;
    }
    console.log(
      "fetchFeedIDS function initialized: 1",
      currentUserID,
      currentFeedID,
    );

    console.log("current offset being passed: ", offSet);

    try {
      // Fetch asset list with scores
      const response = await axios.get("https://genz-staging.feeltiptop.com/api/fillPQ", {
        params: {
          offSet: offSet + 5,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      });

      console.log("response data: ", response.data.data);

      let assetList = response.data.data[0];
      setEndReached(response.data.data[1]);

      if (assetList.length == 0) {
        return;
      }

      console.log("Feed Id queue data: ", response.data);
      setOffSet(offSet + 5);

      if (assetList.length === 0) {
        navigate("/uploadresume", {
          state: {
            message:
              "No posts available for you at the moment. Please upload your resume to get started.",
          },
        }); // Redirect to the resume upload page if there are no posts
      }
      console.log("assetList: ", assetList);

      // Create a mapping of asset IDs to their scores
      const assetScoreMap = assetList.reduce((map, asset) => {
        map[asset.asset_id] = asset.score;
        return map;
      }, {});

      console.log("assetScoreMap: ", assetScoreMap);

      // Extract just asset IDs
      const justAssets = assetList.map((asset) => asset.asset_id);

      console.log("justAssets: ", justAssets);

      // Fetch new posts for the asset IDs
      const newPostsResponse = await axios.get(
        "https://genz-staging.feeltiptop.com/api/getAssetPosts",
        {
          params: {
            assetIDList: justAssets,
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        },
      );

      console.log("newPosts: ", newPostsResponse.data);

      if (newPostsResponse.data.length === 0) {
        navigate("/uploadresume");
      }

      const sub_array = posts.slice(
        posts.length - newPostsResponse.data.length,
        posts.length,
      );
      console.log("sub_array: ", sub_array);

      setPosts((prevPosts) => {
        const cur = new Set(prevPosts.map((post) => post.AssetID));
        const newPost = newPostsResponse.data.filter(
          (post) => !cur.has(post.AssetID),
        );
        return [...prevPosts, ...newPost];
      });

      // Sort newPosts based on assetScoreMap
      // const sortedPosts = newPostsResponse.data.sort((a, b) => {
      //   const scoreA = assetScoreMap[a.AssetID] || 0;
      //   const scoreB = assetScoreMap[b.AssetID] || 0;
      //   return scoreB - scoreA; // Descending order
      // });

      // console.log("sortedPosts: ", sortedPosts);
    } catch (error) {
      console.error("Error fetching feed IDs:", error);
      setError(error.message);
    }
  };

  const fetchUniqueFeedSession = async () => {
    if (feedIdQueue.length > 0) {
      setSeen((prevState) => {
        const newAssetIDs = posts.map((post) => post.AssetID);
        // Make a new Set with the previous state and extra values
        const updatedSet = new Set([...prevState, ...newAssetIDs]);
        return updatedSet;
      });

      setCurrentFeedID(feedIdQueue[0]);

      setFeedIdQueue((prevState) => {
        const [, ...rest] = prevState;
        return rest;
      });
    } else {
      fetchFeedIDS();
    }
  };
  const handleScroll = () => {
    console.log("boolean check", feedIdInUrl);
    // Buffer to account for differences in calculation across browsers
    const scrollBuffer = 10; // Adjust the buffer as needed

    // Check if the user has scrolled to the bottom of the page
    if (
      window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - scrollBuffer &&
      feedIdInUrl !== true
    ) {
      // Call the `fetchUniqueFeedSession` function to fetch a new feed session
      fetchUniqueFeedSession();
    }
  };

  const debouncedHandleScroll = debounce(handleScroll, 200);

  useEffect(() => {
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [debouncedHandleScroll]);

  const renderedPosts = posts.map((post) => (
    <div key={post.AssetID} className="w-screen max-w-lg border">
      <div className="h-12 w-full"></div>

      {/** Header */}
      <div className="fixed-footer">
        <div className="m-auto flex max-w-lg items-center justify-between">
          <img src={logo} alt="Logo" className="h-8" />
          <Timer feedStartTime={feedStartTime} />
          <div
            className="cursor-pointer hover:scale-105"
            onClick={handleLogOut}
          >
            <img src={logoutIcon} alt="Logout" className="h-8 w-8" />
          </div>
        </div>
      </div>

      {/** Content */}
      <div className="p-4">
        <div>
          <div className="flex gap-3">
            <div className="h-10 w-10">
              {post.BrandProfilePicture ? (
                <img
                  src={`https://genz-staging.feeltiptop.com/${post.BrandProfilePicture}`}
                  alt="Company Icon"
                  className="rounded-full"
                />
              ) : (
                <div
                  className=""
                  style={{
                    backgroundImage:
                      "url(https://upload.wikimedia.org/wikipedia/commons/a/a7/Blank_image.jpg)",
                  }}
                ></div>
              )}
            </div>
            <p className="font-semibold">{post.BrandName}</p>
          </div>
          <p className="font-semibold">{post.Caption}</p>
        </div>

        <div className="">{renderAssetContent(post)}</div>

        {post.OpenLink && (
          <a
            href={post.OpenLink}
            target="_blank"
            rel="noopener noreferrer"
            className=""
            onClick={(e) => {
              e.preventDefault();
              clickLink(currentUserID, currentFeedID, post.AssetID);
              window.open(post.OpenLink, "_blank");
            }}
          >
            <span>Open Link</span>
            <div className="">
              <SharelinkIcon />
            </div>
          </a>
        )}
        {/** Comment box */}
        <div className="mt-2">
          <button
            className="flex w-full items-center justify-center py-2 transition-transform hover:scale-105"
            onClick={() => {
              setActiveAssetId(post.AssetID);
              setIsCommentModalOpen(true);
            }}
          >
            <img
              src={
                commentSubmitted[post.AssetID]
                  ? commentImageClicked
                  : commentImage
              }
              alt="Comment"
            />
          </button>
        </div>
      </div>
    </div>
  ));

  return error ? (
    <div>{error}</div>
  ) : (
    <>
      <div className="flex flex-col items-center justify-center gap-4">
        {renderedPosts}
      </div>
      <FeedCommentBox
        userID={currentUserID}
        feedID={currentFeedID}
        activeAssetId={activeAssetId}
        setActiveAssetId={setActiveAssetId}
        token={props.token}
        setToken={props.setToken}
        isCommentModalOpen={isCommentModalOpen}
        setIsCommentModalOpen={setIsCommentModalOpen}
        currentComment={currentComment}
        setCurrentComment={setCurrentComment}
        onCommentSubmit={() => handleCommentSubmit(activeAssetId)}
      />
      {endReached && (
        <div className="flex flex-col items-center justify-center">
          <h1 className="border border-r-4 text-center text-gray-800 drop-shadow-lg">
            You've reached the end
          </h1>
        </div>
      )}
    </>
  );
};

export default Feed;
