import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import backArrow from '../assets/images/arrow_back.svg';
import '../App.css'

function Gender() {
  const [selectedGender, setSelectedGender] = useState('');
  const navigate = useNavigate();

  const { state } = useLocation();
  const { phoneNumber, userName, password, firstName, lastName} = state;

  const genders = ['Man', 'Woman', 'Nonbinary', 'Other', 'Prefer not to say'];

  const handleBackArrow = () => {
    navigate('/EnterName', {state: {phoneNumber, userName, password}});
  };

  const handleSubmit = () => {
    navigate('/DateOfBirth', { state: {phoneNumber, userName, password, firstName, lastName, gender: selectedGender} });
  };

  return (
    <div className="signup-container">
      <div className="arrow-button">
          <img src={backArrow} alt="Logo" className="arrow" onClick={handleBackArrow}/>
      </div>
      <h2 className="title-gender">What's your gender?</h2>
      <p className="gender-description-text">Select One</p>
        {genders.map((gender) => {
          return (
            <div key={gender} className='gender-input-container'>
              <label htmlFor={gender}>
                {gender}
              </label>
              <input
                type="radio"
                id={gender}
                name="gender"
                value={gender}
                checked={selectedGender === gender}
                onChange={() => setSelectedGender(gender)}
              />
            </div>
          );
        })}

        <button
          style={{marginTop:'100px'}}
          className={`button ${selectedGender === '' ? 'grey-background' : 'red-background'}`}
          disabled={selectedGender === ''}
          onClick={handleSubmit}
        >
          Next
        </button>
    </div>
  );
}

export default Gender;
