import { useState, useEffect } from "react";
import QuizDisplay from "./assets/QuizDisplay";
import { MutatingDots } from "react-loader-spinner";
import SolrQueryDate from "./assets/SolrQueryDate";

const numberofQuestion = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const assestmentTypes = {
  1: "multiple choice",
  2: "fill in the blank",
  3: "true or false",
};

const languages = [
  "English",
  "Spanish",
  "French",
  "German",
  "Chinese (Mandarin)",
  "Japanese",
  "Korean",
  "Russian",
  "Arabic",
  "Portuguese",
  "Italian",
  "Dutch",
  "Swedish",
  "Norwegian",
  "Danish",
  "Finnish",
  "Greek",
  "Turkish",
  "Hindi",
  "Thai",
];

const newsTypes = {
  "ttnews-full2": "Regular News",
  summz1: "web News",
  financenews: "Finance News",
};

const TestData = ({ quizOption, setQuizesSelected }) => {
  const { question, answer, correctconcept, wrongconcept, type, selections } =
    quizOption;
  const [save, setSave] = useState(false);

  const [answers, setAnswers] = useState([]);

  const fillAnswersArray = () => {
    const shuffledAnswers = [correctconcept, wrongconcept].sort(
      () => Math.random() - 0.5
    );
    setAnswers(shuffledAnswers);
  };

  const checkAnswer = (e) => {
    const answerSelected = e.target.getAttribute("value");
    if (answerSelected === answer) {
      console.log("Correct Answer");
    } else {
      console.log("Wrong Answer");
    }
  };

  const handleSelectedQuiz = (e) => {
    e.preventDefault();

    const value = e.target.getAttribute("value");

    if (value === "save") {
      setQuizesSelected((prevState) => [...prevState, quizOption]);
      setSave(true);
    } else {
      setQuizesSelected((prevState) =>
        prevState.filter((quiz) => quiz !== quizOption)
      );
      setSave(false);
    }
  };

  return (
    <div className="quiz-container">
      <p>
        <strong>{question}</strong>
      </p>

      <strong>Answer Choices</strong>
      <ol className="quiz-container-ol">
        <QuizDisplay
          type={type}
          selections={selections}
          checkAnswer={checkAnswer}
        />
      </ol>

      <p>Correct Answer: {answer}</p>
      {/* {!save ? (
        <button value="save" onClick={handleSelectedQuiz}>
          Save
        </button>
      ) : (
        <button value="unsave" onClick={handleSelectedQuiz}>
          Unsave
        </button>
      )} */}
    </div>
  );
};

function Quiz({
  setQuizData,
  generateAssests,
  quizOptions,
  setQuizesSelected,
  quizsSelected,
}) {
  const [dateQuery, setDateQuery] = useState("");

  const handleQuestionNumber = (e) => {
    setQuizData((prevState) => ({
      ...prevState,
      questionNumber: parseInt(e.target.value),
    }));
  };

  const [isLoading, setIsloading] = useState(false);

  const handleMainTopic = (e) => {
    setQuizData((prevState) => ({
      ...prevState,
      mainTopic: e.target.value,
    }));
  };

  const handleSubTopic = (e) => {
    setQuizData((prevState) => ({
      ...prevState,
      subTopic: e.target.value,
    }));
  };

  const handleLanguage = (e) => {
    setQuizData((prevState) => ({
      ...prevState,
      language: e.target.value,
    }));
  };

  const handleType = (e) => {
    setQuizData((prevState) => ({
      ...prevState,
      type: parseInt(e.target.value),
    }));
  };

  const handleSubmitAll = (e) => {
    e.preventDefault();

    console.log(quizsSelected);
  };

  useEffect(() => {
    if (quizOptions !== null) {
      console.log(quizOptions.length);
    }
  }, [quizOptions]);

  const handleNewsType = (e) => {
    setQuizData((prevState) => ({
      ...prevState,
      newsType: e.target.value,
    }));
  };

  return (
    <>
      <div>
        <h2>Quiz Categories</h2>
        <br />
        {/* display the number of questions options */}
        <label htmlFor="question-number">(1)</label>
        <select
          onChange={handleQuestionNumber}
          id="question-number"
          name="question-name"
          required
        >
          <option
            required
            placeholder="Select number of questions"
            value="default"
          >
            Number of questions
          </option>
          {numberofQuestion.map((number, index) => (
            <option key={index} value={number}>
              {number}
            </option>
          ))}
        </select>
        <br />

        <label htmlFor="language-select">(2)</label>
        <select
          id="language-select"
          name="language-select"
          required
          onChange={handleLanguage}
        >
          <option placeholder="Select language" value="English">
            Select Language
          </option>
          {languages.map((language, index) => (
            <option key={index} value={language}>
              {language}
            </option>
          ))}
        </select>

        {/* <label htmlFor="News-Type">(3)</label>
        <select
          id="News-Type"
          name="News-Type"
          required
          onChange={handleNewsType}
          //Add mutator here
        >
          <option value="ttnews-full2">Select a news type</option>
          {Object.entries(newsTypes).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </select> */}

        <br />

        <label htmlFor="assesment-type">(3)</label>
        <select
          id="assesment-type"
          name="assesment-type"
          required
          onChange={handleType}
        >
          <option value={assestmentTypes[1]}>Select a assestment Type</option>
          {Object.entries(assestmentTypes).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </select>

        <br />
        <br />
        {/* <label htmlFor="main-topic">(5)</label>
        <input
          onChange={handleMainTopic}
          placeholder="Input main topic: "
        ></input>

        <br />

        <label htmlFor="sub-topic">(6)</label>
        <input 
          onChange={handleSubTopic}
          placeholder="Input sub topic here">
        </input>

        <br />

        <SolrQueryDate setDateQuery={setDateQuery} setQuizData={setQuizData}/> */}

        <button onClick={(event) => generateAssests(event, setIsloading)}>
          Generate Assest(s)
        </button>
      </div>
      {isLoading && (
        <MutatingDots
          visible={true}
          height="100"
          width="100"
          color="#E3330D"
          secondaryColor="#E3330D"
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
      {quizOptions && quizOptions.length > 0 && (
        <div>
          <br />
          <h2>Quizzes generated</h2>
          <br />
          <ul>
            <ul>
              {Array.isArray(quizOptions) &&
                quizOptions.map((quiz, index) => (
                  <TestData
                    key={index}
                    quizOption={quiz}
                    setQuizesSelected={setQuizesSelected}
                  />
                ))}
            </ul>
          </ul>
          {/* <button onClick={handleSubmitAll}>Save</button> */}
        </div>
      )}
    </>
  );
}

export default Quiz;
