import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import logo from "../assets/images/GenZ_Logo.svg";
import { ReactComponent as SharelinkIcon } from "../assets/svg/sharelink-icon.svg";
import logoutIcon from "../assets/svg/logout-icon.svg";
import TextComponent from "../components/TextComponent";
import FeedCommentBox from "../components/FeedCommentBox";
import commentImage from "../assets/reaction_images/Comment.svg";
import commentImageClicked from "../assets/reaction_images/CommentClicked.svg";
import PollComponent from "../components/PollComponent";
import MediaComponent from "../components/MediaComponent";
import "./FeedStyling.css"

const styles = {
  companyIconImage: {
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    objectFit: "contain",
  },
};

const createPostButtonStyle = {
  backgroundColor: "#3897f0",
  color: "#fff",
  padding: "10px 20px",
  borderRadius: "20px",
  cursor: "pointer",
};

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

const Feed = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();

  const [error, setError] = useState("");
  const [currentComment, setCurrentComment] = useState("");
  const [timer, setTimer] = useState("0:00");
  const [sessionEndTime, setSessionEndTime] = useState("");
  const [feedStartTime, setFeedStartTime] = useState("");

  const [currentFeedID, setCurrentFeedID] = useState(0);
  const [currentUserID, setCurrentUserID] = useState(0);

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isFeedComplete, setIsFeedComplete] = useState(false); 
  const [feedIdInUrl, setFeedIdInUrl] = useState(false)


  const [posts, setPosts] = useState([]);
  const [feedIdQueue, setFeedIdQueue] = useState([]);

  const [superlikedAssets, setSuperlikedAssets] = useState({});
  const [sharedAssets, setSharedAssets] = useState({});
  const [commentSubmitted, setCommentSubmitted] = useState({});
  

  const [activeAssetId, setActiveAssetId] = useState(null);
  const [seen, setSeen] = useState(new Set());

  document.title = "GenZ Feed";

  const handleCommentSubmit = (assetId) => {
    // Update the state to indicate that a comment has been submitted for this assetId
    setCommentSubmitted((prev) => ({ ...prev, [assetId]: true }));
  };

  const Timer = ({ feedStartTime }) => {
    useEffect(() => {
      const updateTimer = () => {
        const fstartTime = new Date(feedStartTime);
        const currentTime = new Date();

        const fstartTimeUtc = Date.UTC(
          fstartTime.getFullYear(),
          fstartTime.getMonth(),
          fstartTime.getDate(),
          fstartTime.getHours(),
          fstartTime.getMinutes(),
          fstartTime.getSeconds()
        );

        const currentTimeUtc = Date.UTC(
          currentTime.getFullYear(),
          currentTime.getMonth(),
          currentTime.getDate(),
          currentTime.getHours(),
          currentTime.getMinutes(),
          currentTime.getSeconds()
        );

        const difference = currentTimeUtc - fstartTimeUtc;
        if (difference >= 0) {
          const minutes = Math.floor(difference / 60000);
          const seconds = Math.floor((difference % 60000) / 1000);
          setTimer(
            (minutes < 10 ? "0" : "") +
              minutes +
              ":" +
              (seconds < 10 ? "0" : "") +
              seconds
          );
        }
      };

      const timerInterval = setInterval(updateTimer, 1000);

      return () => clearInterval(timerInterval);
    }, [feedStartTime]);
 
  };

  // Function to fetch posts from the backend
  useEffect(() => {
    const logo = document.querySelector(".logo");
    if (logo) {
      logo.style.visibility = "hidden";
    }

    return () => {
      if (logo) {
        logo.style.visibility = "hidden";
      }
    };
  }, []);

  useEffect(() => {
    // If currentFeedID is not 0 (i.e., it is a valid feed ID), make a GET request to the backend to fetch posts for that feed
    if (currentFeedID !== 0) {
      axios
        .get("https://genz-staging.feeltiptop.com/api/getPosts", {
          // Include the feed ID as a query parameter in the request
          params: { feedID: currentFeedID },
          // Include an Authorization header with a bearer token for authentication
          headers: { Authorization: "Bearer " + props.token },
        })
        .then((response) => {
          // If the server response contains an access token, update the token state
          response.data.access_token &&
            props.setToken(response.data.access_token);
          // Append the new posts to the existing posts
          console.log("response data: ", response.data);
          const filterAssetContent = response.data.filter(
            (post) => !seen.has(post.AssetID)
          );

          setPosts((prevPosts) => [...prevPosts, ...filterAssetContent]);
          // setPosts(...response.data);

          console.log("post ", posts);
        })
        .catch((error) => {
          // If there's an error fetching the posts, set the error state
          setError(error);
        });
    }
  }, [currentFeedID]);

  useEffect(() => {
    console.log(state);
    if (state) {
      console.log("hi");
      console.log(state);
      setCurrentFeedID(state.feedID);
      setCurrentUserID(state.userID);
      setSessionEndTime(state.sessionEndTime);
      setFeedStartTime(state.feedStartTime);
      setFeedIdInUrl(true)
    } else {
      if (
        searchParams.has("feedid") &&
        searchParams.has("userid") &&
        searchParams.has("sessionendtime")
      ) {
        setCurrentFeedID(searchParams.get("feedid"));
        setCurrentUserID(searchParams.get("userid"));
        setSessionEndTime(searchParams.get("sessionendtime"));
        setFeedStartTime(searchParams.get("feedstarttime"));
        setFeedIdInUrl(true)
      } else {
        console.log("here");
        fetchUserInfo();
        fetchFeedIDS();
        // setError("Incomplete Paramter");
      }
    }
  }, [state, searchParams]);

  const handleLogOut = () => {
    axios
      .post(
        "https://genz-staging.feeltiptop.com/api/logout",
        {},
        {
          headers: { Authorization: `Bearer ${props.token}` },
        }
      )
      .then((response) => {
        props.removeToken();
        localStorage.removeItem("authToken");
        navigate("/login");
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

 

  const clickLink = async (userId, feedId, assetId) => {
    try {
      const response = await axios.post(
        "https://genz-staging.feeltiptop.com/api/linkclick",
        {
          assetID: assetId,
          userID: userId,
          feedID: feedId,
        },
        {
          headers: { Authorization: "Bearer " + props.token },
        }
      );
      response.data.access_token && props.setToken(response.data.access_token);
      if (response.data.success) {
        console.log("Link click submitted successfully");
      } else {
        console.error("Server responded with failure");
      }
    } catch (error) {
      console.error("Error submitting link click:", error);
    }
  };

  const renderAssetContent = (post) => {
    const assetContent = JSON.parse(post.AssetContent);

    switch (assetContent.assetType) {
      case "poll":
        return (
          <PollComponent
            assetContent={assetContent}
            userID={currentUserID}
            feedID={currentFeedID}
            activeAssetId={post.AssetID}
            token={props.token}
            setToken={props.setToken}
          />
        );
      case "text":
        return <TextComponent assetContent={assetContent} />;
      case "media":
        return <MediaComponent mediaLink={assetContent.mediaContent} />;
      default:
        return <p>Unsupported asset type: {assetContent.assetType}</p>;
    }
  };

  const fetchUserInfo = () => {
    const token = localStorage.getItem("authToken");

    try {
      axios
        .get("https://genz-staging.feeltiptop.com/api/getCurrentUserInfo", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const data = response.data;
          console.log("fetching user data: ", response.data);
          setCurrentUserID(data.user_id);
        });
    } catch (error) {
      alert("error message: ", error);
    }
  };

  //note to sel: ranme fetchFeedIds -> fetchAssetIDS

  const fetchFeedIDS = async () => {
    console.log(
      "fetchFeedIDS function initialized: 1",
      currentUserID,
      currentFeedID
    );
  
    try {
      // Fetch asset list with scores
      const response = await axios.get("https://genz-staging.feeltiptop.com/api/fillPQ", {
        params: {
          userID: currentUserID,
        },
        headers: {
          Authorization: "Bearer " + props.token,
        },
      });
  
      console.log("Feed Id queue data: ", response.data);
      let assetList = response.data.data;
      console.log("assetList: ", assetList);
  
      // Create a mapping of asset IDs to their scores
      const assetScoreMap = assetList.reduce((map, asset) => {
        map[asset.asset_id] = asset.score;
        return map;
      }, {});
  
      console.log("assetScoreMap: ", assetScoreMap);
  
      // Extract just asset IDs
      const justAssets = assetList.map((asset) => asset.asset_id);
  
      console.log("justAssets: ", justAssets);
  
      // Fetch new posts for the asset IDs
      const newPostsResponse = await axios.get(
        "https://genz-staging.feeltiptop.com/api/getAssetPosts",
        {
          params: {
            assetIDList: justAssets,
          },
          headers: {
            Authorization: "Bearer " + props.token,
          },
        }
      );
  
      console.log("newPosts: ", newPostsResponse.data);
  
      // Sort newPosts based on assetScoreMap
      const sortedPosts = newPostsResponse.data.sort((a, b) => {
        const scoreA = assetScoreMap[a.AssetID] || 0;
        const scoreB = assetScoreMap[b.AssetID] || 0;
        return scoreB - scoreA; // Descending order
      });
  
      console.log("sortedPosts: ", sortedPosts);
  
      setPosts(sortedPosts);
  
    } catch (error) {
      console.error("Error fetching feed IDs:", error);
      setError(error.message);
    }
  };  

  const fetchUniqueFeedSession = async () => {
    if (feedIdQueue.length > 0) {
      setSeen((prevState) => {
        const newAssetIDs = posts.map((post) => post.AssetID);
        // Make a new Set with the previous state and extra values
        const updatedSet = new Set([...prevState, ...newAssetIDs]);
        return updatedSet;
      });

      setCurrentFeedID(feedIdQueue[0]);

      setFeedIdQueue((prevState) => {
        const [, ...rest] = prevState;
        return rest;
      });
    } else {
      fetchFeedIDS();
    }
  };
  const handleScroll = () => {
    console.log("boolean check",feedIdInUrl)
    // Buffer to account for differences in calculation across browsers
    const scrollBuffer = 10; // Adjust the buffer as needed

    // Check if the user has scrolled to the bottom of the page
    if (
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight - scrollBuffer &&
      feedIdInUrl !== true
    ) {
      // Call the `fetchUniqueFeedSession` function to fetch a new feed session
      fetchUniqueFeedSession();
    }
  
  };

  const debouncedHandleScroll = debounce(handleScroll, 200);

  useEffect(() => {
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [debouncedHandleScroll]);

  const renderedPosts = posts.map((post) => (


    <div key={post.AssetID} style={{ width: "100%" }}>
      <div style={{ height: "50px", width: "100%" }}></div>

      <div className="fixed-footer">
        <div className="footer-content">
          <div className="footer-logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className="footer-timer">
            <Timer feedStartTime={feedStartTime} />
          </div>
          <div className="footer-logout" onClick={handleLogOut}>
            <img src={logoutIcon}></img>
          </div>
        </div>
      </div>
      <div className="full-post">
        <div className="full-company-header">
          <div className="post-header">
            <div className="company-icon">
              {post.BrandProfilePicture ? (
                <img
                  src={`https://genz-staging.feeltiptop.com/${post.BrandProfilePicture}`}
                  alt="Company Icon"
                  className="company-icon img"
                />
              ) : (
                <div
                  alt="Blank Company Icon"
                  style={{
                    ...styles.companyIconImage,
                    backgroundImage:
                      "url(https://upload.wikimedia.org/wikipedia/commons/a/a7/Blank_image.jpg)",
                  }}
                />
              )}
            </div>
            <p className="company-name">{post.BrandName}</p>
          </div>
          <p className="caption">{post.Caption}</p>
        </div>
        <div className="content-container">
          <div className="media-and-link-container">
            {renderAssetContent(post)}
          </div>
          {post.OpenLink && (
            <a
              href={post.OpenLink}
              target="_blank"
              className="open-link"
              onClick={(e) => {
                e.preventDefault();
                clickLink(currentUserID, currentFeedID, post.AssetID);
                window.open(post.OpenLink, "_blank");
              }}
            >
              <span className="open-link-text">Open Link</span>
              <div className="open-link-arrow">
                <SharelinkIcon />
              </div>
            </a>
          )}
        </div>
      </div>
      <div style={{ height: "25px" }}></div>
      <button
        onClick={() => {
          setActiveAssetId(post.AssetID);
          setIsCommentModalOpen(true);
        }}
        style={{
          border: "none",
          background: "none",
          padding: 0,
          width: "100%",
        }}
      >
        {" "}
        <img
          style={{ height: "40px", width: "100%" }}
          src={
            commentSubmitted[post.AssetID] ? commentImageClicked : commentImage
          }
        />{" "}
      </button>
    </div>
  ));



  return error ? (
    <div>{error}</div>
  ) : (
    <>
      <div className="feed-container-style">{renderedPosts}</div>
      <FeedCommentBox
        userID={currentUserID}
        feedID={currentFeedID}
        activeAssetId={activeAssetId}
        setActiveAssetId={setActiveAssetId}
        token={props.token}
        setToken={props.setToken}
        isCommentModalOpen={isCommentModalOpen}
        setIsCommentModalOpen={setIsCommentModalOpen}
        currentComment={currentComment}
        setCurrentComment={setCurrentComment}
        onCommentSubmit={() => handleCommentSubmit(activeAssetId)}
      ></FeedCommentBox>
    </>
  );
};

export default Feed;