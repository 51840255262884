import React, { useEffect, useState, useRef } from 'react';
import logo from '../assets/images/GenZ_Logo.svg';
import FilterDropDown from '../components/FilterDropDown';
import SessionOverview from '../components/SessionOverview';
import AssetSummary from '../components/AssetSummary';
import axios from "axios";
import { HashLink } from 'react-router-hash-link';
import { useParams, useSearchParams } from 'react-router-dom';
import {decryptfromHEX} from './encodedecode.js';
import {SECRETPW} from '../useGlobals.js';

function Report() {
    const [searchParams] = useSearchParams();
    const [error, setError] = useState('')
    const [overViewData, setOverViewData] = useState(null)
    const [responseTypeFilterData, setResponeTypeFilterData] = useState([])
    const [genderFilterData, setGednerFilterData] = useState([])
    const [ageFilterData, setAgeFilterData] = useState([])
    const [locationFilterData, setLocationFilterData] = useState([])

    const [assetDataArray, setAssetDataArray] = useState([])
    const [summaryTitle, setSummaryTitle] = useState([])
    const [genderFilter, setGenderFilter] = useState([])
    const [ageFilter, setAgeFilter] = useState([])
    const [responseTypeFilter, setResponseTypeFilter] = useState([])
    const [locationFilter, setLocationFilter] = useState([])

    const feedID = useRef(0)
    const brandID = useRef(0)
    document.title = "GenZ Report"; 


    useEffect(() => {   
        feedID.current = searchParams.get("feedid");
        brandID.current = searchParams.get("brandid");

        if(searchParams.has("p")){
            try {
                let encjson = searchParams.get("p");
                let jsondatastr  = decryptfromHEX(encjson);
                let jsondata = JSON.parse(jsondatastr);
                feedID.current = jsondata.feedID; 
                brandID.current = jsondata.brandID; 
            } catch (error) {
                setError(error);
                throw "invalid params." + error;
                
            }
            
            // console.log(jsondata, jsondata.feedID, feedID);
        }
        else{
            if((!searchParams.has('pw')) || (searchParams.get('pw') != SECRETPW) )
            {
                alert("invalid params");
                setError("invalid params");
                throw "invalid params";
            }

        }
        if((feedID.current == null) || (brandID.current == null)){
                alert("invalid params");
                setError("invalid params");
        }
        

        // console.log(encryptkey);
        axios.get('https://genz-staging.feeltiptop.com/api/getFilters', {
            params: {
                feedID: feedID.current,
                brandID: brandID.current,
            }
            // ,
            // headers:{
            //     'Authorization': 'Bearer ' + props.token
            // }
          })
        .then((response) => {
            // response.data.access_token && props.setToken(response.data.access_token)
            setGenderFilter(response.data.gender)
            setAgeFilter(response.data.age)
            setResponseTypeFilter(response.data.responseType)
            setLocationFilter(response.data.location)
        })
        .catch((error) => {
            setError(error.message);
        });
      }, [])

    useEffect(() => {
        axios.post('https://genz-staging.feeltiptop.com/api/getReport', {
            filters: {
                "Gender": genderFilterData,
                "ResponseType": responseTypeFilterData,
                "Age": ageFilterData,
                "State": locationFilterData
            }, 
            feedID: feedID.current,
            brandID: brandID.current,
          }
        //   , {
        //     headers:{
        //         'Authorization': "Bearer " + props.token
        //     }
        //   }
          )
        .then((response) => {
            // response.data.access_token && props.setToken(response.data.access_token)
            setOverViewData({
                sentimentCount: response.data.agg.sentiment,
                linkClickCount: response.data.agg.linkClick,
                superLikeCount: response.data.agg.superLike,
                commentCount: response.data.agg.comment,
                shareCount: response.data.agg.share,
                ambassadorCount: response.data.agg.total,
                ageDistribution: response.data.agg.ageDistribution,
                locationDistribution: response.data.agg.locationDistribution,
                genderDistribution: response.data.agg.genderDistribution,
                ethnicityDistribution: response.data.agg.ethnicityDistribution,
            })
            setAssetDataArray(
                response.data.assets.map((asset, index) => ({
                    id: index + 1,
                    name: asset.name, 
                    sentimentCount: asset.sentiment,
                    linkClickCount: asset.linkClick,
                    superLikeCount: asset.superLike,
                    commentCount: asset.comment,
                    shareCount: asset.share,
                    ambassadorCount: asset.total,
                    sentimentDistribution: asset.sentimentDistribution,
                    comments: asset.comments,
                    reactions: asset.ambassadorReactions
                }))
            )
            setSummaryTitle(response.data.feedSessionName)
            // console.log(response.data.agg.ethnicityDistribution)
        })
        .catch((error) => {
            setError(error.response.data.message);
        });
    },[responseTypeFilterData, genderFilterData, ageFilterData, locationFilterData]) 

    const ReportContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'left',
        fontFamily: 'Acumin Variable Concept',
        marginBottom: '100px',
        leadingTrim: 'both',
        textEdge: 'cap'
      };

    const ReportHeadingStyle = {
        width: '100%',
        backgroundColor: '#F6F6F6',  // Adjusted color to match Figma
    };

    const ReportTitleStyle = {
        color: '#000',
        fontSize: '64px', 
        marginLeft: '100px',
        marginBottom: '20px', 
        fontWeight: '700'
    }

    const ReportSubTitleStyle = {
        color: '#000',
        fontSize: '64px', 
        marginLeft: '178px',
        marginBottom: '20px', 
        fontWeight: '300',
        color: '#000', 
        fontSize: '24px', 
        marginLeft: '178px', 
        lineHeight: '28.8px', 
        marginTop: '10px', 
        marginBottom: '124px'
    }

    const logoStyle = {
        width: '27.74px',  // Adjusted size to match Figma
        marginBottom: '1px'  // Adjusted margin to match Figma
    };

    const HeaderFrameStyle = {
        display: 'flex',
        justifyContent: 'right',
        padding: '28px 32px',
        height: '38.27px'
    }


    const HeaderLinkStyle = {
        display: 'inline',
        marginLeft: '20px'

    }

    const filterStyle = {
        width: '100%',
    };

    const filterFrameStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'top',
        padding: '100px 100px',

    };

    const genderOptions = genderFilter.map((gender, index) => ({
        value: gender,
        label: gender
    }))

    const responseTypeOptions = responseTypeFilter.map((responseType, index) => ({
        value: responseType,
        label: responseType
    }))
    const ageOptions = ageFilter.map((age, index) => ({
        value: age,
        label: age
    }))

    const stateOptions = locationFilter.map((state, index) => ({
        value: state,
        label: state
    } ))

    const shortCutMap = (asset, index) =>{
        return (
        <HashLink style={HeaderLinkStyle} smooth to={"#Asset" + (index + 1)}>Asset {index + 1}</HashLink>
        )
    }


    const assetMap = (assetProp) => 
        <AssetSummary {...assetProp}>
        </AssetSummary>

    return (
        error ? <div>{error}</div> :
        <div style={ReportContainerStyle}>
            <div style={ReportHeadingStyle}>
                <div style={HeaderFrameStyle}>
                    <ul>
                        {/* <div>
                            <HashLink style={HeaderLinkStyle} smooth to="#overview">Overview</HashLink>
                            {assetDataArray.map(shortCutMap)}
                        </div> */}
                    </ul>
                </div>
                    <h3 style={ReportTitleStyle}>
                        {summaryTitle}
                    </h3>
            </div>
            <div style= {filterStyle}>
                <div style={filterFrameStyle}>
                    <p style={{fontSize: '28px', marginTop: '0px'}}>Filters</p>
                    <div style={{ display: "flex", gap: "1rem"}}>
                        <FilterDropDown name="Response Type" options={responseTypeOptions} setFilter={setResponeTypeFilterData} filterType="response_type"></FilterDropDown>
                        <FilterDropDown name="Gender" options={genderOptions}  setFilter={setGednerFilterData} filterType="gender"></FilterDropDown>
                        <FilterDropDown name="Age" options={ageOptions} setFilter={setAgeFilterData} filterType="age"></FilterDropDown>
                        <FilterDropDown name="Location" options={stateOptions} setFilter={setLocationFilterData} filterType="location"></FilterDropDown>
                    </div>                    
                </div>
            </div>
            <SessionOverview {...overViewData}></SessionOverview>
            <div>
                {assetDataArray.map(assetMap)}
            </div>
        </div>
    );
}


export default Report;
