import React, { useState, useEffect, useRef } from 'react';

const TextComponent = React.memo(function TextComponent({ assetContent }) {
  const [htmlContent, setHtmlContent] = useState('');
  const contentRef = useRef(null);

  useEffect(() => {
    if (assetContent && assetContent.html) {
      setHtmlContent(assetContent.html);
    }
  }, [assetContent]);

  useEffect(() => {
    if (contentRef.current) {
      const elements = contentRef.current.getElementsByTagName('*');
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.width = '100%';
      }
    }
  }, [htmlContent]);

  if (!assetContent || !assetContent.html) {
    return <div>No text content available</div>;
  }

  return (
    <div 
      ref={contentRef}
      className="text-component-content"
      style={{width: "100%"}} 
      dangerouslySetInnerHTML={{ __html: htmlContent }} 
    />
  );
});

export default TextComponent;